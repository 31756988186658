const GAP = 20; // px

const breakpoint1279 = window.matchMedia('(max-width: 1279px)');
const breakpoint767 = window.matchMedia('(max-width: 767px)');

const calculateSlidesPerView = () => {
  if (document.documentElement.clientWidth > 1279) {
    return 3;
  } else if (document.documentElement.clientWidth > 767) {
    return 2;
  } else {
    return 1;
  }
};

const initSliderServices = () => {
  const sliders = document.querySelectorAll('.slider-services');

  if (!sliders.length) {
    return;
  }

  sliders.forEach((slider) => {
    const container = slider.querySelector('.slider-services__container');
    const wrapper = container.querySelector('.slider-services__wrapper');
    const slides = container.querySelectorAll('.slider-services__slide');
    const prevBtn = slider.querySelector('.slider-btn--prev');
    const nextBtn = slider.querySelector('.slider-btn--next');
    const paginationElem = slider.querySelector('.slider-pagination');
    const navigation = {
      prevEl: prevBtn,
      nextEl: nextBtn,
    };
    const pagination = {
      el: paginationElem,
      bulletClass: 'slider-pagination__bullet',
      bulletActiveClass: 'is-active',
      clickable: true,
    };

    const checkIfNeedNavigation = () => {
      if (breakpoint767.matches) {
        const slideWidth = slides[0].offsetWidth;
        const slidesLength = slides.length * (slideWidth + GAP) - GAP;

        return slidesLength > slider.offsetWidth;
      }

      return slides.length > slidesPerView;
    };


    let swiper;
    let slidesPerView = calculateSlidesPerView();
    let isNeedNavigation = checkIfNeedNavigation();

    const initSwiper = () => {
      // eslint-disable-next-line no-undef
      swiper = new Swiper(container, {
        slidesPerView: 'auto',
        spaceBetween: GAP,
        grabCursor: true,
        mousewheel: {
          forceToAxis: true,
        },
        threshold: 10,
        navigation,
        pagination,
        breakpoints: {
          768: {
            slidesPerView,
          },
        },
      });

      if (isNeedNavigation) {
        wrapper.classList.remove('is-centered');
        prevBtn.style.display = null;
        nextBtn.style.display = null;
        paginationElem.style.display = null;
      } else {
        wrapper.classList.add('is-centered');
        prevBtn.style.display = 'none';
        nextBtn.style.display = 'none';
        paginationElem.style.display = 'none';
      }
    };

    const reInitSwiper = () => {
      slidesPerView = calculateSlidesPerView();
      isNeedNavigation = checkIfNeedNavigation();

      if (swiper) {
        swiper.destroy(true, true);
      }

      initSwiper();
    };

    const onWindowResize = () => {
      if (breakpoint1279.matches) {
        const checkResult = checkIfNeedNavigation();

        if (isNeedNavigation !== checkResult) {
          isNeedNavigation = checkResult;
          reInitSwiper();
        }
      }
    };

    initSwiper();

    breakpoint1279.addListener(reInitSwiper);
    breakpoint767.addListener(reInitSwiper);

    window.addEventListener('resize', onWindowResize);
  });
};

export {initSliderServices};
