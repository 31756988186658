import {nanoid} from 'nanoid/non-secure';

const initTableOfContent = () => {
  const tablesOfContent = document.querySelectorAll('[data-toc="parent"]');

  if (!tablesOfContent.length) {
    return;
  }

  tablesOfContent.forEach((_table) => {
    const list = document.querySelector('[data-toc="list"]');
    let items = document.querySelectorAll('[data-toc="item"]');

    if (!list) {
      return;
    }

    if (!items.length) {
      items = document.querySelectorAll('h1:not([data-no-toc]), h2:not([data-no-toc]), h3:not([data-no-toc]), h4:not([data-no-toc])');
    }

    if (!items.length) {
      const contentSection = list.closest('section');

      contentSection.remove();
    }

    let contentItemTagName;

    if (list.tagName === 'OL' || list.tagName === 'UL') {
      contentItemTagName = 'LI';
    } else {
      contentItemTagName = 'DIV';
    }

    items.forEach((item) => {
      const contentItem = document.createElement(contentItemTagName);
      const id = nanoid();

      item.id = id;

      contentItem.innerHTML = `<a href="#${id}">${item.textContent}</a>`;

      list.append(contentItem);
    });
  });
};

export {initTableOfContent};
